import styled from "styled-components"

export const Welcome = styled.section`
  color: #f7fafc;
  letter-spacing: 0.0138889em;
  display: block;
  text-transform: uppercase;
  font-weight: bolder;
  font-style: normal;
  text-align: center;
  line-height: 1.1em;
  grid-column: 1;
  grid-row: 2/ 2;

  @media (min-width: 1024px) {
    font-size: 5rem;
  }

  @media (min-width: 768px) {
    font-size: 4rem;
    margin-top: 3rem;
    text-align: left;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: left;
  }
`
