import React, { Component } from "react"
import { Spring } from "react-spring"

export class DescriptionItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }
  }

  componentDidMount() {
    const { timeout } = this.props

    setTimeout(() => {
      this.setState({
        show: true
      })
      setTimeout(() => {
        this.setState({
          show: false
        })
      }, 1000)
    }, timeout)
    setTimeout(() => {
      this.setState({
        show: true
      })
    }, 4100)
  }

  render() {
    const { children } = this.props
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: this.state.show ? 1 : 0 }}
        config={{ duration: 250 }}>
        {props => (
          <li
            style={{
              ...props,
              color: `whitesmoke`,
              textTransform: `uppercase`,
              fontFamily: `Roboto`
            }}>
            {children}
          </li>
        )}
      </Spring>
    )
  }
}
