import styled from "styled-components"

export const Description = styled.ul`
  color: whitesmoke;
  font-weight: 400;
  letter-spacing: 0.4px;
  font-style: normal;
  line-height: 1.6rem;
  grid-column: 1;
  grid-row: 3/3;
`
