import React from "react"

export const HeaderText = props => {
  const { large, small } = props
  return (
    <div className={`mt-6 mx-4 px-4 pt-4 flex flex-col items-center ${props.className}`}>
      <h1 className='text-2xl md:text-4xl font-semibold text-gray-800'>{large}</h1>
      <p className='text-lg md:text-2xl text-gray-700'>{small}</p>
    </div>
  )
}
