import styled from "styled-components"

export const WelcomeSub = styled.p` 
  font-size: 0.75rem;
  text-align: left;
  font-weight: 400;
  font-family: Roboto;

  @media (min-width: 769px) {
    font-size: 1rem;
  }
`
