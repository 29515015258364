import React from "react";
import "../styles.css";
import Layout from "../components/Layout";
import {
  HeroContent,
  Welcome,
  WelcomeSub,
  Description,
  DescriptionItem
} from "../components/Hero";
import ns_website from "../../assets/img/ns_website.png";
import photography_example from "../../assets/img/photography_example.jpg";
import logo_example from "../../assets/img/logo_example.webp";
import Copywriting from "../../assets/svg/copywriting.svg";
import { menuItems } from "../services/constants";
import { ContactUsSection } from "../components/ContactUsSection";
import { OfferingsSection } from "../components/offerings";
import { HeaderText } from "../components/HeaderText";
import { Link } from "gatsby";
import Helmet from "react-helmet";

export default () => {


  const description =
    "Stand out. Your business deserves it. Custom websites, photography, copywriting and editing, logo design and graphic design. Located in Westlake, Ohio.";

  return (
    <Layout
      showTitleInNavbar={false}
      bgColorClass='bg-transparent'
      heroSlantClassName='hero-slant lg:hero-slant-lg'
      menuItems={menuItems}
      helmetTitle="Westlake, OH | Northeast Ohio | Website Design, Copywriting, Photography, Logo Design | Red Brick Road Studio"
      contentInsideBackground={() => (
        <HeroContent>
          <Welcome>
            <WelcomeSub>Family owned, proudly located in Westlake, OH</WelcomeSub>
            Red Brick Road Studio
          </Welcome>
          <Description>
            <WelcomeSub className="uppercase font-semibold mb-4 text-lg">
              We Offer
            </WelcomeSub>
            <DescriptionItem timeout={0}>
              <Link to='/services/websites' className='hover:underline'>
                Custom Websites
              </Link>
            </DescriptionItem>
            <DescriptionItem timeout={1000}>
              <Link to='/services/photography' className='hover:underline'>
                Photography
              </Link>
            </DescriptionItem>
            <DescriptionItem timeout={2000}>
              <Link to='/services/copywriting' className='hover:underline'>
                Copywriting and Editing
              </Link>
            </DescriptionItem>
            <DescriptionItem timeout={3000}>
              <Link to='/services/logodesign' className='hover:underline'>
                Logo Design
              </Link>
            </DescriptionItem>
          </Description>
        </HeroContent>
      )}>
      <Helmet>
        <link rel='canonical' href={`https://redbrickroadstudio.com`} />
        <meta name='twitter:title' content='Red Brick Road Studio' />
        <meta name='description' content={description} />
        <meta name='twitter:description' content={description} />

        <meta
          name='keywords'
          content='custom website creation, development, marketing photography, professional copywriting and editing, logo design, graphic design, web design, agency, westlake, ohio, northeast ohio'
        />
      </Helmet>

      <HeaderText className='text-center' large='STAND OUT' small='Your business deserves it.' />
      <div className='sm:max-w-xl lg:max-w-5xl sm:mx-auto'>
        <OfferingsSection
          header='Custom Websites'
          subtitle='Who you are is just a click away'
          detail="From minor updates to major rebuilds, we work with you to bring your digital dreams to the customer's screen."
          navActionUrl='/services/websites'
          navAction='View a recent design'
          imgSrc={ns_website}
          exampleDescription='Noon Sharpening, Inc.'
        />
        <OfferingsSection
          flipOrder
          header='Copywriting and Editing'
          subtitle='Content for the personable professional'
          detail='Whether written for you, by you, or a combination of both, your content will always read in the voice of your business.'
          navActionUrl='/services/copywriting'
          navAction='Read examples'
          imgSrc={Copywriting}
        />
        <OfferingsSection
          header='Marketing Photography'
          subtitle='Put your best image forward'
          detail="Colorful or muted, playful or staid, let your images capture viewer's attention. We offer professional in-house photography and photography editing services."
          navActionUrl='/services/photography'
          navAction='Look at recent photos'
          imgSrc={photography_example}
          exampleDescription='Showcasing the new outdoor playground'
        />
        <OfferingsSection
          flipOrder
          header='Logo Design'
          subtitle='Make your mark'
          detail='Instantly recognizable, we believe your logo is the reflection of your business at a glance. Make it a memorable one.'
          navActionUrl='/services/logodesign'
          navAction='View our latest design'
          imgSrc={logo_example}
          exampleDescription="The new logo for Montessori Children's School of Westlake, Ohio"
        />
        <div className='text-gray-800 py-6 md:px-4 mx-6 mt-6 mb-12'>
          <ContactUsSection />
        </div>
      </div>
    </Layout>
  );
};
